<template>
	<!-- 头部 -->
	<div class="sticky">
		<Header></Header>
	</div>
	<!-- banner -->
	<img src="https://kootec.oss-cn-beijing.aliyuncs.com/officeWebsite/mineBanner.png" style="width: 100%;height: 39vw;">
	<!-- 介绍部分 -->
	<div class="introduceBox">
		<div class="flex align-center justify-center">
			<div style="margin-right: 47px;width: 32.6vw;">
				<div class="color666 ft18">
					作为一家专业的数字经济服务商，我们于2008年成立于首都北京，在十余年的发展中，紧跟时代趋势，以技术赋能、陪伴增长为核心理念，在企业数字化应用、电子商务、数字营销、IT基础构建与运维等方面，用专业的服务能力陆续为上百家企业的发展注入数智动力，把握增效与增长的机遇。
				</div>
				<div class="color666 ft18" style="margin-top: 36px;">
					公司在江苏无锡、江西九江设有分支机构，拥有多项专利著作权，拥有软件信息服务进出口资质，并多次获北京市创新基金、北京市文化创意产业发展资金等表彰奖励。
				</div>
				<div class="color000 bold" style="font-size: 36px;margin-top: 85px;">
					可信赖的数字经济服务商 为企业发展提供有力的数智推进引擎
				</div>
			</div>
			<img :src="introduceBg" style="width: 24.4vw; height: 583px" />
		</div>
	</div>
	<!-- 产品案例 -->
	<div class="productWrap">
		<div class="bold color000" style="font-size: 48px;text-align: center;">产品案例</div>
		<div class="color999 ft16" style="margin-top: 32px;text-align: center;">从新应用程序的开发，到现代化、管理和维护，我们涵盖了应用程序生命周期的所有阶段。
		</div>
		<div style="width: 100%;margin-top: 61px;" class="flex align-center justify-between flexWrap">
			<div v-for="(item,index) in productList" :key='index' class="productListWrap">
				<img :src="item.image" style="width: 100%;height: auto;">
				<div class="productBottom">
					<div class="flex">
						<div class="typeStyle">{{item.type}}</div>
					</div>
					<div class="bold color000 oneHidden" style="font-size: 30px;margin-top: 8px;">{{item.title}}</div>
					<div class="color666 ft14 threeHidden" style="margin-top: 31px;height: 57px;">{{item.content}}</div>
				</div>
			</div>
		</div>
	</div>
	<!-- 底部 -->
	<Footer></Footer>
</template>

<script>
	import Header from "../components/header.vue";
	import Footer from "../components/footer.vue";
	import {
		reactive
	} from "@vue/reactivity";
	import {
		useRoute,
		useRouter
	} from "vue-router";
	import {
		toRefs,
		onMounted
	} from "vue";
	export default {
		name: "aboutUs",
		components: {
			Header,
			Footer,
		},
		setup() {
			// 使用router跳转的方法
			const router = useRouter();
			// 获取router传参
			const route = useRoute();
			const data = reactive({
				introduceBg: "https://kootec.oss-cn-beijing.aliyuncs.com/officeWebsite/introduceBg.png", //介绍右侧图片
				productList: [{
						image: 'https://kootec.oss-cn-beijing.aliyuncs.com/officeWebsite/szfw.png',
						type: '数字服务',
						title: '数字经济产业园',
						content: '为发展省内数字经济产业发展，在省内县市落地数字经济产业园区；整个项目包含政府管理平台、企业中台、开放API、风控系统以及大数据平台等多个子系统，集合了工商、税务等跨部门业务集成。',
					},
					{
						image: 'https://kootec.oss-cn-beijing.aliyuncs.com/officeWebsite/szsq.png',
						type: '数字社群',
						title: 'Tecno Spot',
						content: 'Tecno手机是海外市场特别是非洲市场的王者，构建海外56个国家及地区，48种语言的数字系统；融合了品牌产品官网、电商、线上营销活动等丰富的应用',
					},
					{
						image: 'https://kootec.oss-cn-beijing.aliyuncs.com/officeWebsite/szls.png',
						type: '数字零售',
						title: '互联网+新零售时代',
						content: '店加加是一款面向代理商、经销商以及终端店主的线上零售产品；惠刷通是一个店铺通用积分返利与优惠券发放领取平台',
					},
					{
						image: 'https://kootec.oss-cn-beijing.aliyuncs.com/officeWebsite/szyy.png',
						type: '数字运营',
						title: '某集团数据可视化与APP',
						content: '龙发装饰集团在家装行业独树一帜，为了进一步提高签单率以及降低成本、提升效率并推进内部合伙人模式，同时便于管理层监控监管服务全流程，推进数字化战略，全面重构新一代CRM/ERP系统',
					}
				],
			});
			return {
				...toRefs(data),
			};
		},
	};
</script>

<style scoped>
	.introduceBox {
		/* margin: 111px 20.2vw 0 20.2vw; */
		margin: 111px 0 0 0;
	}

	.productWrap {
		background: #F5F5F5;
		width: 100%;
		padding: 5.5vw 19.9vw 4.8vw 19.9vw;
		box-sizing: border-box;
	}

	.productListWrap {
		width: 29.2vw;
		background: #ffffff;
		border-radius: 20px;
		margin-top: 23px;
	}
	
	.productBottom{
		padding: 0 2.2vw 2.1vw 2.5vw;
		box-sizing: border-box;
	}
	
	.typeStyle{
		background: #bd00ff;
		padding: 8px;
		box-sizing: border-box;
		font-size: 18px;
		color: #fff;
		margin-top: 52px;
	}
</style>
